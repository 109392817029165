"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.headChain = exports.series = void 0;
const headChain = {
    chain: Promise.resolve(),
    series(func) {
        return series(func, headChain);
    }
};
exports.headChain = headChain;
function series(func, thiser = headChain) {
    thiser.chain = new Promise((resolve) => {
        return thiser.chain.then(() => {
            return func().then((r) => {
                resolve(r);
            }).catch((e) => console.error(e));
        });
    });
    return thiser.chain;
}
exports.series = series;
