"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Serializable = void 0;
class Serializable {
    fromString(string) {
        try {
            this.fromJSON(JSON.parse(string));
        }
        catch (e) {
            console.error(e);
        }
    }
    toString() {
        try {
            return JSON.stringify(this.toJSON());
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}
exports.Serializable = Serializable;
