"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseManager = void 0;
require("cordova-plugin-purchase");
const rxjs_1 = require("rxjs");
const Global_1 = require("../Global");
class PurchaseManager {
    constructor(gameManager) {
        this.gameManager = gameManager;
        this.storeOpen$ = new rxjs_1.BehaviorSubject(false);
        this.store = window.CdvPurchase.store;
        this.products = [
            CdvPurchase.Test.testProducts.NON_CONSUMABLE
        ];
        if ((0, Global_1.isDevice)()) {
            this.registerProducts()
                .then((items) => {
                this.storeOpen$.next(true);
                items === null || items === void 0 ? void 0 : items.forEach((err) => {
                    if (err.isError) {
                        console.warn('Purchase item issue', err.message);
                    }
                });
            })
                .catch((e) => console.error(e));
        }
    }
    canPurchase(item) {
        var _a;
        return this.storeOpen$.value && ((_a = this.store.products.find((p) => p.id === item)) === null || _a === void 0 ? void 0 : _a.canPurchase);
    }
    productUpdate(product) {
        console.log(product);
    }
    transactionUpdate(trans) {
        console.log(trans);
    }
    registerProducts() {
        this.store.register(this.products);
        this.store.when()
            .productUpdated((product) => this.productUpdate(product))
            .approved((trans) => this.transactionUpdate(trans));
        return this.store.initialize([CdvPurchase.Platform.TEST]);
    }
}
exports.PurchaseManager = PurchaseManager;
