"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WigglyText = void 0;
const pixi_js_1 = require("pixi.js");
const Global_1 = require("../Global");
class WigglyText extends pixi_js_1.Text {
    constructor(app, text, style, canvas) {
        super(text, style, canvas);
        this.app = app;
        this.running = true;
        this.power = (0, Global_1.px)(5.5);
        this.ticker = new pixi_js_1.Ticker();
        this.sprite = new pixi_js_1.Sprite();
        this.noise = new pixi_js_1.NoiseFilter((0, Global_1.px)(0.45));
        this.blur = new pixi_js_1.BlurFilter((0, Global_1.px)(1.5));
        this.padding = 5;
        this.plate = (new pixi_js_1.Graphics());
        this.plate.filters = [this.noise, this.blur];
        this.addChild(this.sprite);
        const desiredFPS = 7;
        const interval = 1000 / desiredFPS;
        let lastTime = 0;
        this.ticker.add((delta) => {
            this.sprite.anchor = this.anchor;
            const currentTime = this.ticker.lastTime;
            if (this.running && currentTime - lastTime >= interval) {
                this.displace();
                lastTime = currentTime;
            }
        });
        this.ticker.start();
        this.displace();
    }
    displace() {
        var _a;
        this.plate.clear()
            .beginFill(0x333333)
            .drawRect(0, 0, this.width + (0, Global_1.px)(this.padding), this.height + (0, Global_1.px)(this.padding))
            .endFill();
        this.noise.seed = Math.random();
        this.sprite.texture.destroy(true);
        this.sprite.texture = this.app.renderer.generateTexture(this.plate);
        (_a = this.displacement) === null || _a === void 0 ? void 0 : _a.destroy();
        this.displacement = new pixi_js_1.DisplacementFilter(this.sprite, this.power);
        this.filters = [this.displacement];
    }
    destroy(options) {
        var _a;
        this.ticker.destroy();
        this.noise.destroy();
        this.blur.destroy();
        this.sprite.destroy({
            texture: true,
            baseTexture: true,
            children: true
        });
        (_a = this.displacement) === null || _a === void 0 ? void 0 : _a.destroy();
        this.plate.destroy(true);
        super.destroy(options);
    }
    // Just to get Typescript to stop wining :(
    setTransform(x, y, scaleX, scaleY, rotation, skewX, skewY, pivotX, pivotY) {
        return super.setTransform(x, y, scaleX, scaleY, rotation, skewX, skewY, pivotX, pivotY);
    }
}
exports.WigglyText = WigglyText;
