"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorAdapter = void 0;
const pixi_js_1 = require("pixi.js");
class ColorAdapter {
    constructor(item) {
        this.item = item;
        this.shader = null;
        if (this.isShader) {
            // We need to make a new Shader for each usage, this is because
            // uniforms may be dependent on where the shader is applied, for example the resolution
            /*
               TODO: Make slots from this, similar to slots on a "cpu", I think creating them constantly is really GPU intensive, lots of instructions sent there!
             */
            this.shader = new item();
        }
    }
    get color() {
        return this.isColor ? (this.item) : 0xffffff;
    }
    get texture() {
        return this.isTexture ? (this.item) : null;
    }
    get brushShader() {
        return this.shader;
    }
    get isShader() {
        return ((typeof this.item !== 'number') && !(this.item instanceof pixi_js_1.Texture));
    }
    get isColor() {
        return typeof this.item === 'number';
    }
    get isTexture() {
        return this.item instanceof pixi_js_1.Texture;
    }
    resolution(width, height) {
        if (this.uniforms) {
            this.uniforms.uResolution = new pixi_js_1.Point(Math.trunc(width), Math.trunc(height));
        }
    }
    get uniforms() {
        return (this.shader ? this.shader.uniforms : null);
    }
    makePlate() {
        return this.isTexture ? pixi_js_1.Sprite.from((this.item)) : new pixi_js_1.Graphics();
    }
    static cache(app, item) {
        return __awaiter(this, void 0, void 0, function* () {
            const me = new ColorAdapter(item);
            if (me.isShader) {
                /*
                    So on some GPU devices, the first time we use a shader we have a delay (0.5s - 1.0s)
                    This does not work well for a "fast paced" drawing game, so what we will do here is
                    Draw a circle with our shader, wait for an animation frame to render, then kill the whole thing.
                    This then will move the Shader Programs onto the GPU
                 */
                const graphics = new pixi_js_1.Graphics();
                const alpha = new pixi_js_1.AlphaFilter(0.5);
                graphics.shader = me.brushShader.shader;
                graphics.filters = [me.brushShader.filter, alpha];
                app.stage.addChild(graphics);
                graphics.beginFill(me.color).drawRect(0, 0, 2, 2).endFill();
                me.resolution(graphics.width, graphics.height);
                return new Promise((resolve) => {
                    requestAnimationFrame(() => {
                        graphics.destroy(true);
                        alpha.destroy();
                        me.destroy();
                        resolve();
                    });
                });
            }
            else {
                me.destroy();
            }
        });
    }
    destroy() {
        var _a;
        (_a = this.shader) === null || _a === void 0 ? void 0 : _a.destroy();
    }
}
exports.ColorAdapter = ColorAdapter;
