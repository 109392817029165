"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultStore = exports.stores = exports.StoreColors = void 0;
const interfaces_1 = require("./interfaces");
var StoreColors;
(function (StoreColors) {
    StoreColors[StoreColors["Black"] = 0] = "Black";
    StoreColors[StoreColors["Red"] = 1] = "Red";
    StoreColors[StoreColors["Green"] = 2] = "Green";
    StoreColors[StoreColors["Blue"] = 3] = "Blue";
    StoreColors[StoreColors["Purple"] = 4] = "Purple";
    StoreColors[StoreColors["Yellow"] = 5] = "Yellow";
    StoreColors[StoreColors["Orange"] = 6] = "Orange";
    StoreColors[StoreColors["Rainbow"] = 7] = "Rainbow";
    StoreColors[StoreColors["Gold"] = 8] = "Gold";
    StoreColors[StoreColors["Colorful"] = 9] = "Colorful";
    StoreColors[StoreColors["Laser"] = 10] = "Laser";
    StoreColors[StoreColors["Stars"] = 11] = "Stars";
    StoreColors[StoreColors["Watercolor"] = 12] = "Watercolor";
})(StoreColors || (exports.StoreColors = StoreColors = {}));
// Store Colors
const store_colors = {
    name: interfaces_1.Store.Color,
    items: [
        {
            value: StoreColors.Black
        },
        {
            value: StoreColors.Red,
            cost_brushes: 20
        },
        {
            value: StoreColors.Blue,
            cost_brushes: 20
        },
        {
            value: StoreColors.Green,
            cost_brushes: 30
        },
        {
            value: StoreColors.Orange,
            cost_brushes: 30
        },
        {
            value: StoreColors.Yellow,
            cost_brushes: 40
        },
        {
            value: StoreColors.Purple,
            cost_brushes: 40
        },
        {
            value: StoreColors.Gold,
            cost_brushes: 100
        },
        {
            value: StoreColors.Rainbow,
            cost_brushes: 200
        },
        {
            value: StoreColors.Colorful,
            cost_streak: 8
        },
        {
            value: StoreColors.Watercolor,
            cost_brushes: 260
        },
        {
            value: StoreColors.Laser,
            cost_brushes: 300
        },
        {
            value: StoreColors.Stars,
            cost_brushes: 500
        }
    ]
};
const stores = [store_colors];
exports.stores = stores;
// What every player gets at the start of the game
const defaultStore = {
    [interfaces_1.Store.Color]: [StoreColors.Black]
};
exports.defaultStore = defaultStore;
